import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Redirect} from "@reach/router"


const NotFoundPage = () => (
  <div>
      
  
  <Layout>   
        {//<Redirect noThrow from="/404"to={"/comingsoon"}/>
        }
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
  </Layout></div>
  )
  


export default NotFoundPage
